"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty2 = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

require("core-js/modules/es.function.name");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.search");

_Object$defineProperty2(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-property"));

var _defineProperties = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-properties"));

var _getOwnPropertyDescriptors = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors"));

var _getOwnPropertyDescriptor = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor"));

var _getOwnPropertySymbols = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/slicedToArray"));

var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _some = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/some"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _defineProperty3 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));

var _ticketSerializer = _interopRequireWildcard(require("@innovorder/ticket-serializer"));

var _localStorageConverter = require("../localStorageConverter");

var _formatCart = require("./formatCart");

function ownKeys(object, enumerableOnly) { var keys = (0, _keys.default)(object); if (_getOwnPropertySymbols.default) { var symbols = (0, _getOwnPropertySymbols.default)(object); if (enumerableOnly) symbols = (0, _filter.default)(symbols).call(symbols, function (sym) { return (0, _getOwnPropertyDescriptor.default)(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context11; (0, _forEach.default)(_context11 = ownKeys(Object(source), true)).call(_context11, function (key) { (0, _defineProperty3.default)(target, key, source[key]); }); } else if (_getOwnPropertyDescriptors.default) { (0, _defineProperties.default)(target, (0, _getOwnPropertyDescriptors.default)(source)); } else { var _context12; (0, _forEach.default)(_context12 = ownKeys(Object(source))).call(_context12, function (key) { (0, _defineProperty2.default)(target, key, (0, _getOwnPropertyDescriptor.default)(source, key)); }); } } return target; }

_ngInjectExport.$inject = ["localStorageService", "$q", "ENV_VARS", "$filter", "iso4217", "$rootScope", "webBridge", "BRIDGE_MESSAGE", "moneylineService", "CHANNELS", "simpleLogService"];

function _ngInjectExport(localStorageService, $q, ENV_VARS, $filter, iso4217, $rootScope, webBridge, BRIDGE_MESSAGE, moneylineService, CHANNELS, simpleLogService) {
  'ngInject';
  /**
   * TICKET EXAMPLE
   * <ticket exclude-tags=""><feedline size="2"></feedline><line alignment="center" emphasis="1" size="3">{{{ restaurantName }}}</line><feedline size="1"></feedline><line alignment="center" emphasis="1" size="2">Commande {{{ orderTicketNumberDescription }}}</line><line alignment="center" emphasis="1" size="2">Prévue pour : {{{ expectedDate }}}</line><line alignment="center" size="2">{{{ modeOfConsumption }}}</line><line alignment="center" emphasis="1" size="1">{{{ paymentMean }}}</line><feedline size="1"></feedline><line alignment="center" emphasis="1" size="2">Nom : {{{ userFistName }}} {{{ userLastName }}}</line><line alignment="center" size="2">Tel : {{{ userAddressPhone }}}</line><line alignment="center" size="1">{{{ userAddressCompiled }}}</line><line alignment="center" size="1">{{{ userAdditionalInfo }}}</line><line alignment="center" emphasis="1" size="1">"{{{ userComment }}}"</line><line alignment="center" emphasis="1" size="1">{{{ orderCreatedAt }}}</line><feedline size="1"></feedline><cart></cart><feedline size="1"></feedline><line alignment="center" emphasis="1" size="1">Merci pour votre confiance</line><line alignment="center" size="1">Ce ticket ne fait pas office de ticket de caisse</line><line alignment="center" size="1">Powered by InnovOrder</line></ticket>
   */

  var recapTicketDefaultConfig = '<ticket><line alignment="center" emphasis="1" size="3">Récapitulatif</line><feedline size="2"></feedline><cart recap="1"></cart><feedline size="2"></feedline></ticket>';
  var printService = this;
  printService.printers = {};
  printService.channel = null;
  printService.printersStatus = {};
  printService.warnings = {
    paperEmpty: false,
    paperNearEnd: false,
    coverOpen: false,
    lowBattery: false
  };

  printService.canPrint = function () {
    for (var i in printService.printers) {
      if (printService.printers[i] && printService.printers[i].tickets && printService.printers[i].tickets.length) {
        return true;
      }
    }

    return false;
  };

  printService.printDelay = {
    TAKE_AWAY: ENV_VARS.PRINT_DELAY.DEFAULT.TAKE_AWAY,
    DELIVERY: ENV_VARS.PRINT_DELAY.DEFAULT.DELIVERY,
    SIT_IN: ENV_VARS.PRINT_DELAY.DEFAULT.SIT_IN,
    DRIVE: ENV_VARS.PRINT_DELAY.DEFAULT.DRIVE
  };
  printService.statusToPrint = {
    placed: true,
    preparing: true,
    done: true
  };
  printService.detectedPrinters = null;
  printService.autoPrint = true;
  printService.printDriveNotificationTicket = false;
  printService.driveNotificationTicketPrinter = null;
  printService.driveNotificationTicketId = null;
  printService.updateUsbPrinterFromDetected = null;
  printService.tickets = [];
  printService.printersRebootDate = {};

  printService.save = function () {
    simpleLogService.info("[Angularcore][PrintService] save starting...");
    var dataToSave = {
      printers: printService.printers,
      printersRebootDate: printService.printersRebootDate,
      autoPrint: printService.autoPrint,
      printDelay: printService.printDelay,
      recapPrinter: printService.recapPrinter,
      recapTicketId: printService.recapTicketId,
      statusToPrint: printService.statusToPrint,
      printDriveNotificationTicket: printService.printDriveNotificationTicket,
      driveNotificationTicketPrinter: printService.driveNotificationTicketPrinter,
      driveNotificationTicketId: printService.driveNotificationTicketId
    };
    simpleLogService.info("[Angularcore][PrintService] dataToSave", dataToSave);
    localStorageService.cookie.set('print', dataToSave);
    simpleLogService.info("[Angularcore][PrintService] save end");
  };

  printService.recover = function () {
    simpleLogService.info("[Angularcore][PrintService] recover starting..."); //TODO : When all reception are >= 2.0.3 replace by localStorageService.cookie.get()

    var dataToRecover = (0, _localStorageConverter.get)(localStorageService, 'print');
    simpleLogService.info("[Angularcore][PrintService] recover dataToRecover", dataToRecover);

    if (dataToRecover) {
      printService.printers = dataToRecover.printers || {};
      printService.printersRebootDate = dataToRecover.printersRebootDate || {};
      printService.recapPrinter = dataToRecover.recapPrinter && printService.printers[dataToRecover.recapPrinter] ? dataToRecover.recapPrinter : null;
      printService.autoPrint = dataToRecover.autoPrint;
      printService.statusToPrint = dataToRecover.statusToPrint;

      if (dataToRecover.printDelay) {
        printService.printDelay = dataToRecover.printDelay;

        if (printService.printDelay.takeAway || printService.printDelay.takeAway == 0) {
          printService.printDelay.TAKE_AWAY = printService.printDelay.takeAway;
          printService.printDelay.takeAway = null;
        }

        if (printService.printDelay.delivery || printService.printDelay.delivery == 0) {
          printService.printDelay.DELIVERY = printService.printDelay.delivery;
          printService.printDelay.delivery = null;
        }
      }

      printService.recapTicketId = dataToRecover.recapTicketId;

      if (dataToRecover.printDriveNotificationTicket === true || dataToRecover.printDriveNotificationTicket === false) {
        printService.printDriveNotificationTicket = dataToRecover.printDriveNotificationTicket;
      }

      if (dataToRecover.driveNotificationTicketId) {
        printService.driveNotificationTicketId = dataToRecover.driveNotificationTicketId;
      }

      if (dataToRecover.driveNotificationTicketPrinter && printService.printers[dataToRecover.driveNotificationTicketPrinter]) {
        printService.driveNotificationTicketPrinter = dataToRecover.driveNotificationTicketPrinter;
      }

      printService.recoverUsbPrinter(true);
    } // We check because reception does not have 3.7.5 update that includes GET_PRINTERS_STATUS bridge message


    if (printService.channel === CHANNELS.CHANNEL_KIOSK.VALUE) {
      printService.getPrintersStatus();
    }

    printService.save();
    simpleLogService.info("[Angularcore][PrintService] recover end");
  };

  printService.setPrinter = function (printer) {
    printService.printers[printer.mac] = printer;
    printService.save();
  };

  printService.updatePrinters = function (printers) {
    var _context, _context2;

    simpleLogService.info("[Angularcore][PrintService] updatePrinters starting...");

    var oldPrinters = _objectSpread({}, printService.printers);

    var newPrinters = (0, _filter.default)(_context = (0, _map.default)(_context2 = (0, _values.default)(printers)).call(_context2, function (printer) {
      if (!oldPrinters[printer.mac]) {
        return undefined;
      }

      return _objectSpread({}, printer, {
        tickets: oldPrinters[printer.mac].tickets
      });
    })).call(_context, function (item) {
      return !!item;
    });
    (0, _forEach.default)(newPrinters).call(newPrinters, function (printer) {
      return printService.setPrinter(printer);
    });
    simpleLogService.info("[Angularcore][PrintService] updatePrinters end");
  };

  printService.setWarnings = function (printers) {
    simpleLogService.info("[Angularcore][PrintService] setWarnings starting...");
    var printerList = (0, _values.default)(printers);
    printService.warnings.paperEmpty = (0, _some.default)(printerList).call(printerList, function (_ref) {
      var statusPaper = _ref.statusPaper;
      return statusPaper === 'PAPER_EMPTY';
    });
    printService.warnings.paperNearEnd = (0, _some.default)(printerList).call(printerList, function (_ref2) {
      var statusPaper = _ref2.statusPaper;
      return statusPaper === 'PAPER_NEAR_END';
    });
    printService.warnings.coverOpen = (0, _some.default)(printerList).call(printerList, function (_ref3) {
      var statusCoverOpen = _ref3.statusCoverOpen;
      return statusCoverOpen === 'TRUE';
    });
    printService.warnings.lowBattery = (0, _some.default)(printerList).call(printerList, function (_ref4) {
      var _context3;

      var statusBatteryLevel = _ref4.statusBatteryLevel;
      return (0, _includes.default)(_context3 = ['BATTERY_LEVEL_0', 'BATTERY_LEVEL_1']).call(_context3, statusBatteryLevel);
    });
    printService.warnings.notOnline = (0, _some.default)(printerList).call(printerList, function (_ref5) {
      var statusOnline = _ref5.statusOnline;
      return statusOnline === 'UNKNOWN';
    });
    printService.warnings.notConnected = (0, _some.default)(printerList).call(printerList, function (_ref6) {
      var statusConnection = _ref6.statusConnection;
      return statusConnection === 'FALSE';
    });
    simpleLogService.info("[Angularcore][PrintService] setWarnings end");
  };

  printService.removePrinter = function (macAddress) {
    delete printService.printers[macAddress];
    delete printService.printersRebootDate[macAddress];
    printService.save();
  };

  printService.setPrintersRebootDate = function (target, printersRebootDate) {
    printService.printersRebootDate[target] = printersRebootDate;
    printService.save();
  };

  printService.setTickets = function (tickets) {
    printService.tickets = tickets;
  };

  printService.addTicket = function (ticket) {
    printService.tickets.push(ticket);
  };

  printService.getTicket = function (ticketId) {
    for (var i = 0; i < printService.tickets.length; i++) {
      if (printService.tickets[i].ticketId == ticketId) {
        return printService.tickets[i];
      }
    }

    return null;
  };

  printService.getPrintersStatus = function () {
    var _context4;

    simpleLogService.info("[Angularcore][PrintService] getPrintersStatus starting...");

    if (!webBridge.ready) {
      return;
    }

    var targetList = (0, _map.default)(_context4 = (0, _values.default)(printService.printers)).call(_context4, function (_ref7) {
      var target = _ref7.target;
      return target;
    });
    simpleLogService.info("[Angularcore][PrintService] getPrintersStatus targetList", targetList);
    webBridge.emitMessage(BRIDGE_MESSAGE.GET_PRINTERS_STATUS, targetList);
    simpleLogService.info("[Angularcore][PrintService] getPrintersStatus end");
  };

  printService.search = function (searchForRecover) {
    simpleLogService.info("[Angularcore][PrintService] search starting...");

    if (webBridge.ready) {
      printService.detectedPrinters = null;
      webBridge.emitMessage(BRIDGE_MESSAGE.PRINTER_START_DISCOVER, null);

      if (!searchForRecover) {
        printService.updateUsbPrinterFromDetected = null;
      }
    }

    simpleLogService.info("[Angularcore][PrintService] search end");
  };

  printService.driveRing = function () {
    if (webBridge.ready) {
      webBridge.emitMessage(BRIDGE_MESSAGE.RING_DRIVE, null);
    }
  };

  printService.ring = function () {
    if (webBridge.ready) {
      webBridge.emitMessage(BRIDGE_MESSAGE.RING, null);
    }
  };

  printService.printIngenicoTicket = function (ticketToPrint, target) {
    if (webBridge.ready) {
      var data = {
        target: 'TCP:'.concat(target),
        ticket: ticketToPrint
      };
      webBridge.emitMessage(BRIDGE_MESSAGE.PRINT_INGENICO_TICKET, data);
    }
  };

  printService.recoverUsbPrinter = function (skipPrintValidation) {
    var _context5;

    simpleLogService.info("[Angularcore][PrintService] recoverUsbPrinter starting...");
    var usbPrinter = getUsbPrinterFromList((0, _map.default)(_context5 = (0, _keys.default)(printService.printers)).call(_context5, function (mac) {
      return printService.printers[mac];
    }));

    if (usbPrinter) {
      if (!skipPrintValidation) {
        printService.testPrint(usbPrinter.mac);
      }

      printService.updateUsbPrinterFromDetected = function () {
        var _context6;

        simpleLogService.info("[Angularcore][PrintService] updateUsbPrinterFromDetected starting...");
        var detectedPrinter = getUsbPrinterFromList((0, _map.default)(_context6 = (0, _keys.default)(printService.detectedPrinters)).call(_context6, function (mac) {
          return printService.detectedPrinters[mac];
        }));
        simpleLogService.info("[Angularcore][PrintService] updateUsbPrinterFromDetected detectedPrinter", detectedPrinter);

        if (detectedPrinter) {
          usbPrinter.target = detectedPrinter.target;
          printService.setPrinter(usbPrinter);
          printService.updateUsbPrinterFromDetected = null;

          if (!skipPrintValidation) {
            printService.testPrint(usbPrinter.mac);
          }
        }

        simpleLogService.info("[Angularcore][PrintService] updateUsbPrinterFromDetected end");
      };

      printService.search(true);
    }

    simpleLogService.info("[Angularcore][PrintService] recoverUsbPrinter end");
  };

  printService.testPrint = function (mac) {
    var printer = printService.printers[mac];
    printTicket({
      config: null
    }, null, printer, true);
  };

  printService.print = function (data) {
    var _context7;

    var _ref8 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref8$isTicketCBOnly = _ref8.isTicketCBOnly,
        isTicketCBOnly = _ref8$isTicketCBOnly === void 0 ? false : _ref8$isTicketCBOnly,
        _ref8$isEcoFriendlyTi = _ref8.isEcoFriendlyTicket,
        isEcoFriendlyTicket = _ref8$isEcoFriendlyTi === void 0 ? true : _ref8$isEcoFriendlyTi;

    simpleLogService.info("[Angularcore][PrintService] print starting...");
    (0, _forEach.default)(_context7 = (0, _values.default)(printService.printers)).call(_context7, function (printer) {
      if (printer.tickets) {
        var _context8;

        (0, _forEach.default)(_context8 = printer.tickets).call(_context8, function (ticketId) {
          var ticket = printService.getTicket(ticketId);

          if (isTicketCBOnly) {
            printTicket({
              config: ''
            }, data, printer, false, isEcoFriendlyTicket);
          } else if (ticket && ticket.name !== 'Ingenico') {
            printTicket(ticket, data, printer, false, isEcoFriendlyTicket);
          }
        });
      }
    });
    simpleLogService.info("[Angularcore][PrintService] print end");
  };

  printService.printDriveNotification = function (data) {
    if (printService.driveNotificationTicketPrinter && printService.printers[printService.driveNotificationTicketPrinter] && printService.driveNotificationTicketId && printService.printDriveNotificationTicket) {
      var driveTicket = printService.getTicket(printService.driveNotificationTicketId);
      printTicket(driveTicket, data, printService.printers[printService.driveNotificationTicketPrinter], false);
    }
  };

  printService.printRecap = function (data) {
    if (printService.recapPrinter && printService.printers[printService.recapPrinter]) {
      var recapTicketConfig = recapTicketDefaultConfig;

      if (printService.recapTicketId) {
        recapTicketConfig = printService.getTicket(printService.recapTicketId);
      }

      printTicket(recapTicketConfig, data, printService.printers[printService.recapPrinter], false);
    }
  };

  printService.init = function (_ref9) {
    var channel = _ref9.channel;
    simpleLogService.info("[Angularcore][PrintService] init starting...");
    var deferred = $q.defer();
    printService.channel = channel;

    if (webBridge.ready) {
      $rootScope.$on(BRIDGE_MESSAGE.RING, function () {
        printService.ring();
      });
      $rootScope.$on(BRIDGE_MESSAGE.RING_DRIVE, function () {
        printService.driveRing();
      });
      webBridge.on(BRIDGE_MESSAGE.PRINTER_LIST_UPDATED, 'PRINT_SERVICE', function (printers) {
        printService.detectedPrinters = circumventAndroidPrinterBridgeBug(printers);

        if (printers && (0, _keys.default)(printers).length > 0 && printService.updateUsbPrinterFromDetected) {
          printService.updateUsbPrinterFromDetected();
        }
      });
      webBridge.on(BRIDGE_MESSAGE.SET_PRINTERS_STATUS, 'PRINT_SERVICE', function (printersStatus) {
        simpleLogService.info("[Angularcore][SET_PRINTERS_STATUS]", printersStatus);
        printService.printersStatus = printersStatus;
        printService.setWarnings(printersStatus);
        $rootScope.$broadcast(BRIDGE_MESSAGE.KIOSK_PRINTERS_STATUSES_UPDATED, null);
      });
      webBridge.on(BRIDGE_MESSAGE.ORDER_PRINT_ERROR, 'PRINT_SERVICE', function (order) {
        $rootScope.$broadcast(BRIDGE_MESSAGE.ORDER_PRINT_ERROR, order);
        printService.recover();
      });
      webBridge.on(BRIDGE_MESSAGE.PRINT_TICKET_SUCCEEDED, 'PRINT_SERVICE', function (order) {
        // backward compatibility android web app bridge v2.1.3
        var orderId = order.orderId ? order.orderId : order;
        $rootScope.$broadcast(BRIDGE_MESSAGE.PRINT_TICKET_SUCCEEDED, orderId);
        $rootScope.$broadcast(BRIDGE_MESSAGE.ORDER_PRINT_SUCCEEDED, order);
      });
      deferred.resolve(printService);
    } else {
      //for developpers, DO NOT REMOVE
      deferred.resolve(printService);
    }

    simpleLogService.info("[Angularcore][PrintService] init end");
    return deferred.promise;
  };

  function printTicket(ticket, orderData, printerData, isTest) {
    var isEcoFriendlyTicket = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;

    if (orderData && orderData.cartRawData) {
      var cart = (0, _formatCart.formatCartData)(orderData, $filter, iso4217);
      orderData.cartRawData = (0, _stringify.default)(cart);
    }

    var eventData = {
      ticket: ticket,
      xmlTicket: ticket.config,
      orderData: orderData || '',
      isTest: isTest || false,
      printerData: printerData,
      localeStringMap: _ticketSerializer.LOCALE_STRING_MAP || {},
      isEcoFriendlyTicket: isEcoFriendlyTicket,
      isKitchenPrinter: printerData.isKitchenPrinter,
      printersStatus: printService.printersStatus
    };

    if (moneylineService.isRunning) {
      var serializedTicket = (0, _ticketSerializer.default)(ticket.config, orderData, isEcoFriendlyTicket);
      moneylineService.printTicket(serializedTicket);
    } else {
      $rootScope.$broadcast(BRIDGE_MESSAGE.ORDER_PRINT_STARTING, eventData);
    }
  }

  printService.previewTicket = function (xmlTicket, orderData) {
    if (orderData) {
      var cart = (0, _formatCart.formatCartData)(orderData, $filter, iso4217);
      orderData.cartRawData = (0, _stringify.default)(cart);
    }

    return (0, _ticketSerializer.getTicketPreview)(xmlTicket, orderData);
  };

  function getUsbPrinterFromList(printers) {
    return printers && printers.length > 0 ? (0, _reduce.default)(printers).call(printers, function (acc, curr) {
      var _context9;

      return !curr.ip && (0, _indexOf.default)(_context9 = curr.target).call(_context9, 'USB:/') > -1 ? curr : acc;
    }, null) : null;
  }
}

function circumventAndroidPrinterBridgeBug(printers) {
  return replacePrinterTargets(printers);
}

function replacePrinterTargets(printers) {
  var _context10;

  // fix bugs in TCP printer on previous version than v2.2.2
  return (0, _reduce.default)(_context10 = (0, _entries.default)(printers)).call(_context10, function (acc, _ref10) {
    var _ref11 = (0, _slicedToArray2.default)(_ref10, 2),
        name = _ref11[0],
        config = _ref11[1];

    if (!config.target) {
      return _objectSpread({}, acc, (0, _defineProperty3.default)({}, name, config));
    }

    var target = config.target.replace('TCPS', 'TCP').replace('[local_printer]', '');

    var _config = _objectSpread({}, config, {
      target: target
    });

    return _objectSpread({}, acc, (0, _defineProperty3.default)({}, name, _config));
  }, {});
}